#post-detail {
  .detail-content {
    @apply flex flex-col;
  }

  .post-variants {
    .variant-option {
      @apply flex items-center gap-x-1 p-2 text-grey-8 leading-[24px] cursor-pointer;
      @apply border border-grey-8 rounded-8px hover:bg-grey-2;

      .icon {
        background-image: url('~images/icons/checkbox_unchecked.svg');
      }

      &.selected {
        border-color: var(--color-blue-10);
        color: var(--color-blue-10);

        .icon {
          background-image: url('~images/icons/checkbox_checked_blue.svg');
        }

        .file-type-icon {
          @apply bg-blue-10;
        }
      }
    }
  }

  .post-like:hover .heart-wrapper .heart{
    .tl,.tr,.bl,.br{
      &:after{
        background: #E2264D;
      }
    }
  }
}

#post-more {
  @apply relative;

  input#post-more-dropdown {
    @apply hidden;
  }

  label[for="post-more-dropdown"] {
    @apply inline-block w-6 h-6 hover:bg-grey-1 rounded-full;
    background-image: url('~images/icons/More.svg');
  }

  .post-more-dropdown-wrap {
    @apply absolute hidden top-8 right-0 bg-grey-4 rounded-xl;

    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.6);

    a {
      @apply py-3 px-4 rounded-xl hover:bg-grey-1;
    }
  }

  #post-more-dropdown:checked{
    ~ label {
      @apply bg-grey-1;
    }
    ~ .post-more-dropdown-wrap {
      @apply block;
    }
  }
}

#post-actions {
  .post-like, .post-comment, .post-save, .post-share {
    @apply h-11;

    background: rgba(0,0,0,.5);

    &:hover {
      background: rgba(47, 50, 55, 0.50);
    }
  }
  .post-like {
    .icon {
      @apply w-[30px] h-[30px];

      background-image: url('~images/icons/likes.svg');
    }

    &.focus .icon {
      background-image: url('~images/icons/likes-focus.svg');
    }
  }

  .post-comment {
    .icon {
      @apply w-[30px] h-[30px];

      background-image: url('~images/icons/comment.svg');
    }
  }

  .post-save {
    .icon {
      @apply w-[28px] h-[28px];

      background-image: url('~images/icons/save.svg');
    }

    &.focus .icon {
      background-image: url('~images/icons/save-focus.svg');
    }
  }

  .post-share {
    [data-action] .icon {
      @apply w-[28px] h-[28px];

      background-image: url('~images/icons/shared.svg');
    }

    &:not(.close) {
      background: rgba(47, 50, 55, 0.50);
    }

    .nav-dropdown-mask {
      @apply left-auto right-0 translate-x-0 lg:right-1/2 lg:translate-x-[50%];

      &:before {
        display: none;
      }

      .nav-dropdown {
        @apply p-4 mt-3 bg-grey-4;
      }
    }
  }
}

#post-image-slide-component {
  position: relative;

  .swiper-wrapper {
    width: 1px;
  }

  #post-images-slide .swiper-slide {
    cursor: zoom-in
  }

  .swiper-button-prev, .swiper-button-next {
    @apply absolute z-10;

    top: 50%;
    transform: translateY(-50%);
  }

  .swiper-button-prev{
    @apply left-4;
  }

  .swiper-button-next{
    @apply right-4;
  }

  .swiper-btn {
    @apply w-[28px] h-[28px] opacity-60 hover:opacity-100 cursor-pointer;
    background-image: url('~images/scroll-top.svg');
  }

  .swiper-pagination {
    @apply absolute top-3.5 left-3.5 z-10;

    background: rgba(255, 255, 255, 0.20);
  }

  .swiper-pagy {
    @apply w-6 h-6;

    background-image: url('~images/images_pagy.svg');
  }

  .swiper-lazy-preloader {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #181C1F;
  }
}

#related-posts {
  @apply grid gap-4 grid-cols-2 smd:grid-cols-3 lg:grid-cols-1;

  .post-item {
    .post-image {
      @apply relative;

      a{
        @apply rounded-7px overflow-hidden;

        .image-mask {
          @apply absolute bottom-0 left-0 w-full h-7 hidden rounded-b-8px;

          backdrop-filter: blur(1.5px);
        }
      }
      label:not(.close) {
        @apply w-8 h-8 absolute right-4 bottom-4 hidden;
        @apply bg-no-repeat bg-center rounded-full cursor-pointer;

        background-color: var(--color-grey-3);
        background-size: 20px 20px;
        background-image: url('~images/icons/shared.svg');

        &:hover {
          background-color: var(--color-grey-5);
        }
      }
      .post-price-container {
        @apply hidden;
      }

      .post-data-container {
        @apply hidden;
      }

      &:hover, input:checked ~ {
        a .image-mask {
          @apply block;
        }
        label {
          @apply block;
        }
        .post-price-container {
          @apply flex;

          margin-bottom: 3px
        }
        .post-data-container {
          @apply flex;
        }
      }

      .shared-mask {
        @apply fixed top-0 left-0 hidden flex items-center justify-center text-center;

        background: rgba(0,0,0,.5);

        width: 100%;
        height: 100%;

        @media (min-width: 780px) {
          @apply absolute top-full left-0 h-auto;

          width: calc(100% + 16px);
          background: none
        }

        label.close {
          @apply absolute block md:hidden w-4 h-4 top-1.5 right-0 cursor-pointer;
        }

        .shared-content {
          @apply p-4 bg-grey-4 rounded-7px;

          @media (min-width: 780px) {
            box-shadow: 8px 8px 12px rgba(0,0,0,.3)
          }
        }

        .shared-icons {
          @apply grid grid-cols-4 gap-y-4;

          .shared-icon {
            @apply flex flex-col items-center text-grey-5 cursor-pointer;
          }

          hr {
            @apply col-span-4 border-grey-5;
          }
        }
      }

      input:checked ~ .shared-mask {
        @apply flex items-center justify-center;

        z-index: 10;

        @media (min-width: 780px) {
          @apply block;

          margin: 0 -8px
        }
      }

      .free-tag {
        color: #19791D;
        background-color: #2CFF67;
        border-radius: 4px;
        line-height: 16px;
      }

      .pv-tag {
        background-color: #E60044;
        border-radius: 4px;
        line-height: 16px;
      }

    }
    .post-info {
      @apply rounded-b-7px overflow-hidden;

      .display-name {
        color: #C4C4C4
      }
    }
    .post-price {
      @apply text-12px;
      text-shadow: 0px 1px 4px rgba(0,0,0,.7);
    }
    .post-original-price {
      @apply ml-2 text-12px text-grey-5 line-through;
      text-shadow: 0px 1px 4px rgba(0,0,0,.7);
    }
    .post-view-count {
      font-size: 12px;
      color: #EAEAEA;
      text-shadow: 0px 4px 4px rgba(0,0,0,.05);

      .icon {
        @apply bg-no-repeat bg-center bg-contain;
        background-image: url('~images/icons/view-count.svg');
        filter: invert(10%) sepia(10%) saturate(0%) hue-rotate(180deg) brightness(95%) contrast(87%) drop-shadow(5px 5px 5px rgba(0,0,0,.15));
      }
    }
    .post-likes-count {
      font-size: 12px;
      color: #EAEAEA;
      .icon {
        @apply bg-no-repeat bg-center bg-contain;
        background-image: url('~images/icons/likes.svg');
      }
    }
    .post-downloads-count {
      font-size: 12px;
      color: #EAEAEA;
      .icon {
        @apply bg-no-repeat bg-center bg-contain;
        background-image: url('~images/download.svg');
      }
    }
  }
}
