#about-us {
  .w-container {
    @apply px-8 smd:px-6 smd:px-8;
  }
  .bg-grid {
    @apply relative bg-repeat-y bg-top;

    background-image: url('~images/about_us/grid.png');
    background-size: 1920px auto;

    &:before {
      @apply absolute top-0 left-0 w-full h-full;

      content: '';
      background: top center no-repeat url('~images/about_us/halo-up.png'), linear-gradient(180deg, rgba(0,0,0,0) 30%, rgba(0,0,0,1) 45%, rgba(0,0,0,0) 60%, rgba(0,0,0,0) 85%, rgba(0,0,0,.3) 95%, rgba(0,0,0,1) 100%);
      background-size: 1920px auto;
    }
  }
  .bg-wave {
    @apply bg-no-repeat bg-center;

    background-image: url('~images/about_us/wave.png');
    background-size: 1920px auto;
  }
  .bg-halo {
    @apply bg-[1024px_auto] bg-center bg-no-repeat lg:bg-top lg:bg-[1920px_auto];

    background-image: url('~images/about_us/halo-down.png');
  }

  #session-1 {
    @apply relative overflow-hidden;

    .image-compare-block {
      @apply relative top-[60px] md:top-0 left-1/2 -translate-x-1/2 aspect-[1370/1284] w-[120vw] max-w-[456px] smd:max-w-[800px] md:max-w-[1370px];
    }
    .icv__control {
      top: 25%;
      height: 65%;
    }
    .icv__theme-wrapper {
      top: 15%;
    }
  }

  #session-5 {
    .image {
      transition: opacity 0.25s ease-in-out;
    }
    a:hover {
      .image {
        opacity: 0.7;
      }
    }
  }

  .btn {
    @apply inline-block p-0 text-16px font-medium rounded-full leading-8;
  }
  .btn-outline-primary {
    @apply text-white border-[#FF694A] border bg-[#32150F] hover:bg-[#92564A];
  }
  .btn-outline-secondary {
    @apply text-white border-[#737576] border bg-[#323638] hover:bg-[#4D5153];
  }

  .line-footer {
    padding: 0;

    .line-footer-animation {
      position: relative;
    }
  }
}