#profile_models {
  height: 100%;

  .unread-point {
    top: -4px;
    right: -4px
  };
  .bg-unread {
    background-color: #504143;
  };
  .grid-custom {
    grid-template-columns: 35fr 18fr 18fr;
  };

  @mixin dropdown-triangle-klass {
    @apply absolute;
    content: '';
    display: block;
    top: 0.5rem;
    width: 1rem;
    height: 1rem;
    background: #4A4C50;
    border-top-right-radius: 25%;
    transform: rotate(-53deg) skewX(-15deg);
  }
  
  .nav-dropdown-mask {
    @apply absolute z-20 left-1/2;
    transform: translateX(-70%);
  
    @media (min-width: 560px) {
      transform: translateX(-50%);
    }
  
    &::before {
      @include dropdown-triangle-klass;
      left: 46.5%;
    }
  }
  
  .nav-dropdown-mask-component {
    @apply absolute z-20 left-1/2;
    transform: translateX(-50%);
  
    &::before {
      @include dropdown-triangle-klass;
      left: 46.5%;
    }
  }
  
  [data-nav-dropdown-direction='left'] {
    .nav-dropdown-mask {
      @apply -left-2;
      transform: translateX(0%);
  
      &::before {
        @apply left-3;
      }
    }
  }
  
  [data-nav-dropdown-direction='right'] {
    .nav-dropdown-mask {
      @apply left-auto -right-2;
      transform: translateX(0%);
  
      &::before {
        @apply left-auto right-3;
      }
    }
  }
  
  .nav-dropdown-wrap {
    @apply rounded-8px shadow-27px;
  }
  
  .nav-dropdown {
    @apply relative p-2 mt-4 text-gray-10 font-medium rounded-8px;
    @apply bg-[#4A4C50];
  }
  
  .notify-mask {
    &::before {
      @screen sm {
        @include dropdown-triangle-klass;
        right: 4.25rem;
      }
    }
  }
  
  @media (max-width: 705px) {
    :not(.close) .notify-mask {
      animation: slideIn .4s cubic-bezier(0,1.3,.91,.92) forwards;
    }
  }
  
  @keyframes slideIn {
    from {
      top: 100%;
    }
    to {
      top: 25%;
    }
  }
  
  @keyframes slideOut {
    from {
      top: 25%;
    }
    to {
      top: 100%;
    }
  }
}

#sorted_models, #profile_phrozen_verified {
  @mixin dropdown-triangle-klass {
    @apply absolute;
    content: '';
    display: block;
    top: 0.5rem;
    width: 1rem;
    height: 1rem;
    background: #575D66;
    border-top-right-radius: 25%;
    transform: rotate(-53deg) skewX(-15deg);
  }
  
  .nav-dropdown-mask {
    @apply absolute z-20 left-1/2;
    transform: translateX(-80%);
  
    &::before {
      @include dropdown-triangle-klass;
      left: 76.5%;
    }
  }
  
  .nav-dropdown-mask-component {
    @apply absolute z-20 left-1/2;
    transform: translateX(-50%);
  
    &::before {
      @include dropdown-triangle-klass;
      left: 46.5%;
    }
  }
  
  [data-nav-dropdown-direction='left'] {
    .nav-dropdown-mask {
      @apply -left-2;
      transform: translateX(0%);
  
      &::before {
        @apply left-3;
      }
    }
  }
  
  [data-nav-dropdown-direction='right'] {
    .nav-dropdown-mask {
      @apply left-auto -right-2;
      transform: translateX(0%);
  
      &::before {
        @apply left-auto right-3;
      }
    }
  }
  
  .nav-dropdown-wrap {
    @apply rounded-8px shadow-27px;
  }
  
  .nav-dropdown {
    @apply relative p-2 mt-4 text-gray-10 font-medium rounded-8px;
    @apply bg-grey-1;
  }
  
  .notify-mask {
    &::before {
      @screen sm {
        @include dropdown-triangle-klass;
        right: 4.25rem;
      }
    }
  }
  
  @media (max-width: 705px) {
    :not(.close) .notify-mask {
      animation: slideIn .4s cubic-bezier(0,1.3,.91,.92) forwards;
    }
  }
  
  @keyframes slideIn {
    from {
      top: 100%;
    }
    to {
      top: 25%;
    }
  }
  
  @keyframes slideOut {
    from {
      top: 25%;
    }
    to {
      top: 100%;
    }
  }

  .review-search-form {
    .comment-order-type {
      @apply flex items-center gap-x-2;

      .icon {
        @apply inline-block w-5 h-5;
        content: '';
        background-image: url('~images/icons/sort.svg');
      }

      label {
        @apply text-16px font-medium cursor-pointer leading-[20px];
      }

      input:checked + label {
        @apply text-primary;

      }
    }
    .search-icon {
      @apply absolute left-0 top-0 w-6 h-6 ml-5 mt-2 hover:cursor-pointer;
      
      background-image: url('~images/icons/search.svg');
      filter: brightness(.5);
      background-repeat: no-repeat;
    }

    .review-search-bar {
      padding-left: 56px;
    }

    .review-search-bar::-webkit-search-cancel-button {
      filter: invert(50%) sepia(20%) saturate(200%) hue-rotate(180deg) brightness(50%) contrast(90%);
      cursor: pointer;
    }
  }
}
