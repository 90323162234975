@mixin collection-page-hyperlink {
  @apply text-[#E94C2B] font-bold hover:underline ; 
}

.collections-campaign-banner {
  @apply w-full flex flex-col sm:flex-row  py-4 sm:py-6 bg-grey-7;

}

.campaign-card .campaign-content a,
.posts-index .campaign-block-content a {
  @include collection-page-hyperlink ;
}

.campaign-block-title {
  @apply relative text-24px md:text-36px border-primary font-bold capitalize mt-10 mb-6 pl-6;
  border-width: 0 0 0 10px;
}

.campaign-card {
  @apply transition-all duration-300;

  &:hover {
    transform: translateY(-5px);
    box-shadow: rgba(38, 38, 38, 0.05) 0px 2px 15px 2px;
  }

  .campaign-image-wrapper {
    @apply w-full;
    
    .aspect-container {
      @apply relative w-full;
      padding-bottom: 29.1%;
    }
  }

  background: var(--newpost-background);

  .campaign-data-container {
    @apply hidden;
    backdrop-filter: blur(1.5px);

    .post-view-count,
    .post-likes-count,
    .post-downloads-count {
      text-shadow: 0px 4px 4px rgba(0,0,0,.05);
      font-size: 12px;
      color: #EAEAEA;

      .icon {
        @apply bg-no-repeat bg-center bg-contain;
        filter: invert(10%) sepia(10%) saturate(0%) hue-rotate(180deg) brightness(95%) contrast(87%) drop-shadow(5px 5px 5px rgba(0,0,0,.15));
      }
    }

    .post-view-count .icon {
      background-image: url('~images/icons/view-count.svg');
    }
    .post-likes-count .icon {
      background-image: url('~images/icons/likes.svg');
    }
    .post-downloads-count .icon {
      background-image: url('~images/download.svg');
    }
  }

  &:hover .campaign-data-container {
    @apply flex;
  }
}

#campaign-container  {
    .comment-order-type {
      @apply flex items-center gap-x-2;

      .icon {
        @apply inline-block w-5 h-5;
        content: '';
        background-image: url('~images/icons/sort.svg');
      }

      .order-type-btn {
        @apply text-grey-3 font-medium cursor-pointer leading-[20px] text-16px;
      
        &:not(.active):hover {
          @apply text-grey-5;
        }
      
        &.active {
          @apply text-primary;
        }
      }
    }
    .search-icon {
      @apply absolute left-0 top-0 w-6 h-6 ml-5 mt-2 hover:cursor-pointer;
      
      background-image: url('~images/icons/search.svg');
      filter: brightness(.5);
      background-repeat: no-repeat;
    }

    .review-search-bar {
      padding-left: 56px;
    }

    .review-search-bar::-webkit-search-cancel-button {
      filter: invert(50%) sepia(20%) saturate(200%) hue-rotate(180deg) brightness(50%) contrast(90%);
      cursor: pointer;
    }

    .icon-container {
    margin-top: 16px;

    @media (min-width: 560px) {
      margin-top: 32px;
    }
    @media (min-width: 784px) {
      margin-top: 80px;
    }
  }
  .icon {
    &.link_to {
      background-size: 20px 20px;
      background-image: url('~images/link_to_no_bg.svg');
    }
  } 
}

// Skeleton Screen Styles
.skeleton-card {
  @apply rounded-7px overflow-hidden bg-grey-4;


  .skeleton-data-container {
    .skeleton-title {
      width: 80%;
    }

    .skeleton-metrics {
      @apply mt-2;

      .skeleton-metric {
        width: 30%;
      }
    }
  }
}
