.profile-dashboard-tabs {
  .tab {
    position: relative;

    img {
      filter: brightness(30%) contrast(90%);
    }

    &::after {
      content: '';
      display: none;

      position: absolute;
      left: 50%;
      bottom: 0;

      width: 142px;
      height: 4px;

      transform: translateX(-50%);

      background-color: var(--color-primary);

    } 

    &.current {
      &::after {
        display: block;
      }
      img {
        filter: none ;
      }
      color: white;
    }
  }
}

.review-container {
  .review-rating {
    .icon {
      @apply inline-block w-5 h-5 mr-2;

      content: '';
      background-image: url('~images/icons/icon_star_empty.svg');

      &.star-hover {
        background-image: url('~images/icons/icon_star_hover.svg');
      }

      &.star {
        background-image: url('~images/icons/icon_star.svg');
      }
    }
  }
}
 
.sort-triangle {
  position: relative;
}

.sort-triangle::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 2px;
  left: 2px;
  border-bottom: 6px solid #6E747C;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  cursor: pointer;
  @media (min-width: 735px) {
    left: 8px;
  }
}

.sort-triangle.asc::after {
  border-bottom: 6px solid #E94C2B;
}

.sort-triangle::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 10px;
  left: 2px;
  border-top: 6px solid #6E747C;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  cursor: pointer;
  
  @media (min-width: 735px) {
    left: 8px;
  }
}

.sort-triangle.desc::before {
  border-top: 6px solid #E94C2B;
}

.increase {
  color: #4AE324;
  .icon {
    background-image: url('~images/arrow-increase.svg');
  }
}
.decline {
  color: #FF8989;
  .icon {
    background-image: url('~images/arrow-decline.svg');
  }
}
.steady, .infinity {
  color: #99A0A8;
}
.infinity {
  .icon {
    background-image: url('~images/info-white.svg');
    position: absolute;
    top: -1.5rem;
    right: -0.5rem;
    filter: brightness(60%) saturate(10%);
  }
}
.individual-post, .individual-post-hr {
  width: 42rem;
}

.drop-shadow-avatar {
  .creator {
    @apply p-2;
  }
  &.creator::before {
      content: '';
      display: block;
      border: 2.5px solid;
      top: 50%;
      left: 50%;
      width: calc(100% - 0.3rem);
      height: calc(100% - 0.3rem);
      transform: translate(-50%, -50%);

      @apply absolute rounded-full border-gray-50;
  }
  &.creator::after {
      content: '';
      display: block;
      border: 3px solid;
      top: 50%;
      left: 50%;
      width: calc(100% + 1px) ;
      height: calc(100% + 1px);
      transform: translate(-50%, -50%);

      @apply absolute rounded-full border-orange-20;
  }
}

.payout-date-link {
  &::after {
    content: '';
    position: absolute;
    width: 90%; 
    bottom: 10px;
    height: 1px;
    background-color: currentColor;
    opacity: 0; 
  }

  &:hover {
    &::after {
      opacity: 1; // hover 时显示下划线
    }
  }
}

.role-tabs .role-tabs-button {
  @apply rounded-[100px] py-1 px-2;
  &.active {
    @apply text-[#FF694A] border border-[#FF694A];
  }
  &:not(.active) {
    @apply text-[#8B8B8B];
    &:hover {
      @apply text-[#CACACA];
    }
  }
}

.header-menu {
  .nav-dropdown {
    .designer_avatar_links,
    .user_avatar_links {
      display: none;
    }
    
    &:has(.role-tabs input#creator-tab:checked) {
      .designer_avatar_links {
        display: block;
      }
    }
    
    &:has(.role-tabs input#user-tab:checked) {
      .user_avatar_links {
        display: block;
      }
    }
  }
  
  .role-tabs {
    label.role-tabs-button {
      @apply inline-block min-w-[80px] sm:min-w-[88px] text-center;
      cursor: pointer;
      &:hover {
        @apply text-[#CACACA];
      }
    }
    
    input {
      @apply hidden;
    }
    
    input:checked + label.role-tabs-button {
      color: white;
      border: none;
      background: linear-gradient(98.93deg, #FF694A 15.91%, #9C07E3 100%);
      background-blend-mode: color-dodge;
    }
  }
}